<template>
  <v-container class="lighten-5 container">
    <v-row no-gutters>
      <v-col>
        <v-card id="diarios" class="mx-auto ml-1 mr-1">

          <v-card-title>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-3" color="secundary" v-bind="attrs" v-on="on">
                  mdi-newspaper-check
                </v-icon>
              </template>
              <span>Tapa de los diarios de hoy</span>
            </v-tooltip> Tapa de los diarios de hoy
          </v-card-title>

        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="80%" @keydown.esc="cancel">
      <v-card>
        <v-img :src="pic" alt="" contain />

        <v-card-actions class="justify-end">
          <v-btn text @click="cancel">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="mb-6" no-gutters>
      <v-col style="text-align: center;">
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <v-card-text>
            <h2>Tapa del diario Ámbito Financiero</h2>
            <div class="listyle">
              <div class="fixed-container" style="width: 100%;">

                <v-img aspect-ratio="0.75" lazy-src="/img/sin_foto.jpg" max-width="100%" @click="openPic('https://titulos.com.ar/'+tapas.ambito)" :src="`https://titulos.com.ar/`+tapas.ambito"></v-img>

              </div>

            </div>

          </v-card-text>

        </v-card>
      </v-col>
      <v-col style="text-align: center;">
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <v-card-text>
            <h2>Tapa del diario BAE Negocios</h2>
            <div class="listyle">
              <div class="fixed-container" style="width: 100%;">

                <v-img aspect-ratio="0.83" lazy-src="/img/sin_foto.jpg" max-width="100%" @click="openPic('https://titulos.com.ar/'+tapas.buenos-aires-economico)" :src="`https://titulos.com.ar/`+tapas.bae"></v-img>

              </div>

            </div>

          </v-card-text>

        </v-card>
      </v-col>

      <v-col style="text-align: center;">
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <v-card-text>
            <h2>Tapa del diario Clarín</h2>
            <div class="listyle">
              <div class="fixed-container" style="width: 100%;">

                <v-img aspect-ratio="0.72" lazy-src="/img/sin_foto.jpg" max-width="100%" @click="openPic('https://titulos.com.ar/'+tapas.clarin)" :src="`https://titulos.com.ar/`+tapas.clarin"></v-img>

              </div>

            </div>

          </v-card-text>

        </v-card>
      </v-col>

      <v-col style="text-align: center;">
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <v-card-text>
            <h2>Tapa del diario Crónica</h2>
            <div class="listyle">
              <div class="fixed-container" style="width: 100%;">

                <v-img aspect-ratio="0.84" lazy-src="/img/sin_foto.jpg" max-width="100%" @click="openPic('https://titulos.com.ar/'+tapas.cronica)" :src="`https://titulos.com.ar/`+tapas.cronica"></v-img>

              </div>

            </div>

          </v-card-text>

        </v-card>
      </v-col>
    </v-row>

    <v-row class="mb-6" no-gutters>
      <v-col style="text-align: center;">
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <v-card-text>
            <h2>Tapa del diario La Nación</h2>
            <div class="listyle">
              <div class="fixed-container" style="width: 100%;">

                <v-img aspect-ratio="0.67" lazy-src="/img/sin_foto.jpg" max-width="100%" @click="openPic('https://titulos.com.ar/'+tapas.lanacion)" :src="`https://titulos.com.ar/`+tapas.lanacion"></v-img>

              </div>

            </div>

          </v-card-text>

        </v-card>
      </v-col>
      <v-col style="text-align: center;">
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <v-card-text>
            <h2>Tapa del diario Página 12</h2>
            <div class="listyle">
              <div class="fixed-container" style="width: 100%;">

                <v-img aspect-ratio="0.79" lazy-src="/img/sin_foto.jpg" max-width="100%" @click="openPic('https://titulos.com.ar/'+tapas.pagina12)" :src="`https://titulos.com.ar/`+tapas.pagina12"></v-img>

              </div>

            </div>

          </v-card-text>

        </v-card>
      </v-col>

      <v-col style="text-align: center;">
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <v-card-text>
            <h2>Tapa del Diario Popular</h2>
            <div class="listyle">
              <div class="fixed-container" style="width: 100%;">

                <v-img aspect-ratio="0.81" lazy-src="/img/sin_foto.jpg" max-width="100%" @click="openPic('https://titulos.com.ar/'+tapas.diariopopular)" :src="`https://titulos.com.ar/`+tapas.diariopopular"></v-img>

              </div>

            </div>

          </v-card-text>

        </v-card>
      </v-col>

      <v-col style="text-align: center;">
        <v-card class="mx-auto my-2 ml-1 mr-1">

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>





<script>

import axios from "axios";
export default {
  name: "Titulos",

  data: () => ({
    pic: "",
    dialog: false,
    tapas: [],
  }),

  methods: {
    openPic(image) {
      this.dialog = true;
      this.pic = image;
    },
    cancel() {
      this.dialog = false;
    },
  },

  async mounted() {
    try {
      const res = await axios.get("https://titulos.com.ar/tapas-diarios.json");

      this.tapas = res.data.tapas;

      //console.log(this.tapas.ambito);

      //console.log(this.noticias);
    } catch (e) {
      console.error(e);
    }
  },
};
</script>
<style scoped>
.v-card__subtitle,
.v-card__text {
  padding: 25px !important;
}

.v-card__title {
  padding: 15px !important;
}
</style>
