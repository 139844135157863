<template>
  <div>
    <Palabras />
    <Diarios />
    <MasLeidas />
  </div>
</template>

<script>
import Palabras from "@/components/palabras.vue";
import Diarios from "@/components/diarios.vue";
import MasLeidas from "@/components/mas-leidas.vue";

export default {
  name: "Home",
  components: {
    Palabras,
    Diarios,
    MasLeidas,
  },
};
</script>