<template>
  <v-container class="lighten-5 container">
    <v-row no-gutters>
      <v-col>
        <v-card class="mx-auto ml-1 mr-1">

          <v-card-title>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-3" color="secundary" v-bind="attrs" v-on="on">
                  mdi-newspaper-check
                </v-icon>
              </template>
              <span>Títulos de las noticias más leídas en cada medio</span>
            </v-tooltip> Títulos más leídos
          </v-card-title>

        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-6" no-gutters>
      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>
          <v-img height="60" lazy-src="/img/ambito_03.svg" src="/img/ambito_03.svg" contain></v-img>

          <v-card-text>

            <router-link class="link" :to="{ path:'/general/Ámbito/'+limpiaTexto(noticias_ambito_0_titulo)+'/'+noticias_ambito_0_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_ambito_0_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_ambito_0_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Ámbito/'+limpiaTexto(noticias_ambito_1_titulo)+'/'+noticias_ambito_1_id}">
              <div class="listyle">
                <div class="fixed-container">

                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_ambito_1_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_ambito_1_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Ámbito/'+limpiaTexto(noticias_ambito_2_titulo)+'/'+noticias_ambito_2_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_ambito_2_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_ambito_2_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Ámbito/'+limpiaTexto(noticias_ambito_3_titulo)+'/'+noticias_ambito_3_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_ambito_3_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_ambito_3_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Ámbito/'+limpiaTexto(noticias_ambito_4_titulo)+'/'+noticias_ambito_4_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_ambito_4_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_ambito_4_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <div style="height:10px;"></div>

          </v-card-text>

        </v-card>
      </v-col>

      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>
          <v-img height="60" lazy-src="/img/clarin_03.svg" src="/img/clarin_03.svg" contain></v-img>

          <v-card-text>

            <router-link class="link" :to="{ path:'/general/Clarín/'+limpiaTexto(noticias_clarin_0_titulo)+'/'+noticias_clarin_0_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_clarin_0_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_clarin_0_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Clarín/'+limpiaTexto(noticias_clarin_1_titulo)+'/'+noticias_clarin_1_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_clarin_1_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_clarin_1_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Clarín/'+limpiaTexto(noticias_clarin_2_titulo)+'/'+noticias_clarin_2_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_clarin_2_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_clarin_2_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Clarín/'+limpiaTexto(noticias_clarin_3_titulo)+'/'+noticias_clarin_3_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_clarin_3_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_clarin_3_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Clarín/'+limpiaTexto(noticias_clarin_4_titulo)+'/'+noticias_clarin_4_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_clarin_4_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_clarin_4_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <div style="height:10px;"></div>

          </v-card-text>

        </v-card>
      </v-col>

      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">
          <div style="height:10px;"></div>
          <v-img height="60" lazy-src="/img/la_nacion_03.svg" src="/img/la_nacion_03.svg" contain></v-img>

          <v-card-text>

            <router-link class="link" :to="{ path:'/general/La Nación/'+limpiaTexto(noticias_lanacion_0_titulo)+'/'+noticias_lanacion_0_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_lanacion_0_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_lanacion_0_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/La Nación/'+limpiaTexto(noticias_lanacion_1_titulo)+'/'+noticias_lanacion_1_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_lanacion_1_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_lanacion_1_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/La Nación/'+limpiaTexto(noticias_lanacion_2_titulo)+'/'+noticias_lanacion_2_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_lanacion_2_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_lanacion_2_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/La Nación/'+limpiaTexto(noticias_lanacion_3_titulo)+'/'+noticias_lanacion_3_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_lanacion_3_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_lanacion_3_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/La Nación/'+limpiaTexto(noticias_lanacion_4_titulo)+'/'+noticias_lanacion_4_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_lanacion_4_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_lanacion_4_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <div style="height:10px;"></div>

          </v-card-text>

        </v-card>
      </v-col>

      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>
          <v-img height="60" lazy-src="/img/infobae_03.svg" src="/img/infobae_03.svg" contain></v-img>

          <v-card-text>

            <router-link class="link" :to="{ path:'/general/Infobae/'+limpiaTexto(noticias_infobae_0_titulo)+'/'+noticias_infobae_0_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_infobae_0_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_infobae_0_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Infobae/'+limpiaTexto(noticias_infobae_1_titulo)+'/'+noticias_infobae_1_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_infobae_1_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_infobae_1_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Infobae/'+limpiaTexto(noticias_infobae_2_titulo)+'/'+noticias_infobae_2_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_infobae_2_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_infobae_2_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Infobae/'+limpiaTexto(noticias_infobae_3_titulo)+'/'+noticias_infobae_3_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_infobae_3_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_infobae_3_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Infobae/'+limpiaTexto(noticias_infobae_4_titulo)+'/'+noticias_infobae_4_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_infobae_4_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_infobae_4_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <div style="height:10px;"></div>

          </v-card-text>

        </v-card>
      </v-col>

    </v-row>

    <v-row class="mb-6" no-gutters>
      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>
          <v-img height="60" lazy-src="/img/el_cronista_03.svg" src="/img/el_cronista_03.svg" contain></v-img>

          <v-card-text>

            <router-link class="link" :to="{ path:'/general/El Cronista/'+limpiaTexto(noticias_cronista_0_titulo)+'/'+noticias_cronista_0_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_cronista_0_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_cronista_0_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/El Cronista/'+limpiaTexto(noticias_cronista_1_titulo)+'/'+noticias_cronista_1_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_cronista_1_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_cronista_1_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/El Cronista/'+limpiaTexto(noticias_cronista_2_titulo)+'/'+noticias_cronista_2_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_cronista_2_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_cronista_2_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/El Cronista/'+limpiaTexto(noticias_cronista_3_titulo)+'/'+noticias_cronista_3_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_cronista_3_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_cronista_3_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/El Cronista/'+limpiaTexto(noticias_cronista_4_titulo)+'/'+noticias_cronista_4_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_cronista_4_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_cronista_4_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <div style="height:10px;"></div>

          </v-card-text>

        </v-card>
      </v-col>

      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>
          <v-img height="60" lazy-src="/img/cronica_03.svg" src="/img/cronica_03.svg" contain></v-img>

          <v-card-text>

            <router-link class="link" :to="{ path:'/general/Crónica/'+limpiaTexto(noticias_cronica_0_titulo)+'/'+noticias_cronica_0_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_cronica_0_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_cronica_0_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Crónica/'+limpiaTexto(noticias_cronica_1_titulo)+'/'+noticias_cronica_1_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_cronica_1_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_cronica_1_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Crónica/'+limpiaTexto(noticias_cronica_2_titulo)+'/'+noticias_cronica_2_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_cronica_2_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_cronica_2_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Crónica/'+limpiaTexto(noticias_cronica_3_titulo)+'/'+noticias_cronica_3_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_cronica_3_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_cronica_3_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/Crónica/'+limpiaTexto(noticias_cronica_4_titulo)+'/'+noticias_cronica_4_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_cronica_4_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_cronica_4_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <div style="height:10px;"></div>

          </v-card-text>

        </v-card>
      </v-col>

      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>
          <v-img height="60" lazy-src="/img/bae_03.svg" src="/img/bae_03.svg" contain></v-img>

          <v-card-text>

            <router-link class="link" :to="{ path:'/general/BAE Negocios/'+limpiaTexto(noticias_baenegocios_0_titulo)+'/'+noticias_baenegocios_0_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_baenegocios_0_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_baenegocios_0_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/BAE Negocios/'+limpiaTexto(noticias_baenegocios_1_titulo)+'/'+noticias_baenegocios_1_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_baenegocios_1_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_baenegocios_1_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/BAE Negocios/'+limpiaTexto(noticias_baenegocios_2_titulo)+'/'+noticias_baenegocios_2_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_baenegocios_2_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_baenegocios_2_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/BAE Negocios/'+limpiaTexto(noticias_baenegocios_3_titulo)+'/'+noticias_baenegocios_3_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_baenegocios_3_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_baenegocios_3_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/BAE Negocios/'+limpiaTexto(noticias_baenegocios_4_titulo)+'/'+noticias_baenegocios_4_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_baenegocios_4_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_baenegocios_4_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <div style="height:10px;"></div>

          </v-card-text>

        </v-card>
      </v-col>

      <v-col>
        <v-card class="mx-auto my-2 ml-1 mr-1">

          <div style="height:10px;"></div>
          <v-img height="60" lazy-src="/img/el_destape_04.svg" src="/img/el_destape_04.svg" contain></v-img>

          <v-card-text>

            <router-link class="link" :to="{ path:'/general/El Destape/'+limpiaTexto(noticias_eldestape_0_titulo)+'/'+noticias_eldestape_0_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_eldestape_0_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_eldestape_0_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/El Destape/'+limpiaTexto(noticias_eldestape_1_titulo)+'/'+noticias_eldestape_1_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_eldestape_1_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_eldestape_1_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/El Destape/'+limpiaTexto(noticias_eldestape_2_titulo)+'/'+noticias_eldestape_2_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_eldestape_2_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_eldestape_2_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/El Destape/'+limpiaTexto(noticias_eldestape_3_titulo.replace(/\w/g, ''))+'/'+noticias_eldestape_3_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_eldestape_3_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_eldestape_3_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <v-divider class="mx-4"></v-divider>

            <router-link class="link" :to="{ path:'/general/El Destape/'+limpiaTexto(noticias_eldestape_4_titulo)+'/'+noticias_eldestape_4_id}">
              <div class="listyle">
                <div class="fixed-container">
                  <v-img class="square img-redondeada" width="110" height="110" lazy-src="/img/sin_foto.jpg" :src="noticias_eldestape_4_foto" />
                </div>
                <div class="my-2 text-subtitle-1">

                  <v-text-field v-html="`<h4>`+trunca_cadena(noticias_eldestape_4_titulo)+`</h4>`" />

                </div>
              </div>
            </router-link>

            <div style="height:10px;"></div>

          </v-card-text>

        </v-card>
      </v-col>

    </v-row>
 <!--    <div align="center">
<Adsense text-justify-center
    data-ad-client="ca-pub-1704998024151646"
    data-ad-slot="2204674867">
    </Adsense>
     </div>-->
  </v-container>
</template>


<script>
import axios from "axios";


export default {
  name: "Titulos",

  data: () => ({
    medios1: [
      { medio: "ambito" },
      { medio: "clarin" },
      { medio: "lanacion" },
      { medio: "infobae" },
    ],
    medios2: [
      { medio: "cronista" },
      { medio: "cronica" },
      { medio: "baenegocios" },
      { medio: "eldestape" },
    ],

    noticias_ambito_0_id: "",
    noticias_ambito_0_titulo: "",
    noticias_ambito_0_foto: "",

    noticias_ambito_1_id: "",
    noticias_ambito_1_titulo: "",
    noticias_ambito_1_foto: "",

    noticias_ambito_2_id: "",
    noticias_ambito_2_titulo: "",
    noticias_ambito_2_foto: "",

    noticias_ambito_3_id: "",
    noticias_ambito_3_titulo: "",
    noticias_ambito_3_foto: "",

    noticias_ambito_4_id: "",
    noticias_ambito_4_titulo: "",
    noticias_ambito_4_foto: "",

    noticias_clarin_0_id: "",
    noticias_clarin_0_titulo: "",
    noticias_clarin_0_foto: "",

    noticias_clarin_1_id: "",
    noticias_clarin_1_titulo: "",
    noticias_clarin_1_foto: "",

    noticias_clarin_2_id: "",
    noticias_clarin_2_titulo: "",
    noticias_clarin_2_foto: "",

    noticias_clarin_3_id: "",
    noticias_clarin_3_titulo: "",
    noticias_clarin_3_foto: "",

    noticias_clarin_4_id: "",
    noticias_clarin_4_titulo: "",
    noticias_clarin_4_foto: "",

    noticias_lanacion_0_id: "",
    noticias_lanacion_0_titulo: "",
    noticias_lanacion_0_foto: "",

    noticias_lanacion_1_id: "",
    noticias_lanacion_1_titulo: "",
    noticias_lanacion_1_foto: "",

    noticias_lanacion_2_id: "",
    noticias_lanacion_2_titulo: "",
    noticias_lanacion_2_foto: "",

    noticias_lanacion_3_id: "",
    noticias_lanacion_3_titulo: "",
    noticias_lanacion_3_foto: "",

    noticias_lanacion_4_id: "",
    noticias_lanacion_4_titulo: "",
    noticias_lanacion_4_foto: "",

    noticias_infobae_0_id: "",
    noticias_infobae_0_titulo: "",
    noticias_infobae_0_foto: "",

    noticias_infobae_1_id: "",
    noticias_infobae_1_titulo: "",
    noticias_infobae_1_foto: "",

    noticias_infobae_2_id: "",
    noticias_infobae_2_titulo: "",
    noticias_infobae_2_foto: "",

    noticias_infobae_3_id: "",
    noticias_infobae_3_titulo: "",
    noticias_infobae_3_foto: "",

    noticias_infobae_4_id: "",
    noticias_infobae_4_titulo: "",
    noticias_infobae_4_foto: "",

    noticias_cronista_0_id: "",
    noticias_cronista_0_titulo: "",
    noticias_cronista_0_foto: "",

    noticias_cronista_1_id: "",
    noticias_cronista_1_titulo: "",
    noticias_cronista_1_foto: "",

    noticias_cronista_2_id: "",
    noticias_cronista_2_titulo: "",
    noticias_cronista_2_foto: "",

    noticias_cronista_3_id: "",
    noticias_cronista_3_titulo: "",
    noticias_cronista_3_foto: "",

    noticias_cronista_4_id: "",
    noticias_cronista_4_titulo: "",
    noticias_cronista_4_foto: "",

    noticias_cronica_0_id: "",
    noticias_cronica_0_titulo: "",
    noticias_cronica_0_foto: "",

    noticias_cronica_1_id: "",
    noticias_cronica_1_titulo: "",
    noticias_cronica_1_foto: "",

    noticias_cronica_2_id: "",
    noticias_cronica_2_titulo: "",
    noticias_cronica_2_foto: "",

    noticias_cronica_3_id: "",
    noticias_cronica_3_titulo: "",
    noticias_cronica_3_foto: "",

    noticias_cronica_4_id: "",
    noticias_cronica_4_titulo: "",
    noticias_cronica_4_foto: "",

    noticias_baenegocios_0_id: "",
    noticias_baenegocios_0_titulo: "",
    noticias_baenegocios_0_foto: "",

    noticias_baenegocios_1_id: "",
    noticias_baenegocios_1_titulo: "",
    noticias_baenegocios_1_foto: "",

    noticias_baenegocios_2_id: "",
    noticias_baenegocios_2_titulo: "",
    noticias_baenegocios_2_foto: "",

    noticias_baenegocios_3_id: "",
    noticias_baenegocios_3_titulo: "",
    noticias_baenegocios_3_foto: "",

    noticias_baenegocios_4_id: "",
    noticias_baenegocios_4_titulo: "",
    noticias_baenegocios_4_foto: "",

    noticias_eldestape_0_id: "",
    noticias_eldestape_0_titulo: "",
    noticias_eldestape_0_foto: "",

    noticias_eldestape_1_id: "",
    noticias_eldestape_1_titulo: "",
    noticias_eldestape_1_foto: "",

    noticias_eldestape_2_id: "",
    noticias_eldestape_2_titulo: "",
    noticias_eldestape_2_foto: "",

    noticias_eldestape_3_id: "",
    noticias_eldestape_3_titulo: "",
    noticias_eldestape_3_foto: "",

    noticias_eldestape_4_id: "",
    noticias_eldestape_4_titulo: "",
    noticias_eldestape_4_foto: "",
  }),

  methods: {
    trunca_cadena(texto) {
      if (texto.length > 100) {
        texto = texto.substring(0, 100) + "...";
      }
      return texto;
    },

    limpiaTexto(titulo) {
      titulo = titulo.toLowerCase();      
      titulo = titulo.replace(/[^\w\d\s]/g, "");
      titulo = titulo.replace(/[\s]/g, "-");
      titulo = titulo.substring(0, 100);
      return titulo;
    },
  },

  async mounted() {
    try {
      const res = await axios.get("https://titulos.com.ar/mas_leidas.json");

      this.noticias_ambito_0_id = res.data.resultados.ambito[0][0][0];
      this.noticias_ambito_0_titulo = res.data.resultados.ambito[0][0][1];
      this.noticias_ambito_0_foto = res.data.resultados.ambito[0][0][3];

      this.noticias_ambito_1_id = res.data.resultados.ambito[0][1][0];
      this.noticias_ambito_1_titulo = res.data.resultados.ambito[0][1][1];
      this.noticias_ambito_1_foto = res.data.resultados.ambito[0][1][3];

      this.noticias_ambito_2_id = res.data.resultados.ambito[0][2][0];
      this.noticias_ambito_2_titulo = res.data.resultados.ambito[0][2][1];
      this.noticias_ambito_2_foto = res.data.resultados.ambito[0][2][3];

      this.noticias_ambito_3_id = res.data.resultados.ambito[0][3][0];
      this.noticias_ambito_3_titulo = res.data.resultados.ambito[0][3][1];
      this.noticias_ambito_3_foto = res.data.resultados.ambito[0][3][3];

      this.noticias_ambito_4_id = res.data.resultados.ambito[0][4][0];
      this.noticias_ambito_4_titulo = res.data.resultados.ambito[0][4][1];
      this.noticias_ambito_4_foto = res.data.resultados.ambito[0][4][3];

      this.noticias_clarin_0_id = res.data.resultados.clarin[0][0][0];
      this.noticias_clarin_0_titulo = res.data.resultados.clarin[0][0][1];
      this.noticias_clarin_0_foto = res.data.resultados.clarin[0][0][3];

      this.noticias_clarin_1_id = res.data.resultados.clarin[0][1][0];
      this.noticias_clarin_1_titulo = res.data.resultados.clarin[0][1][1];
      this.noticias_clarin_1_foto = res.data.resultados.clarin[0][1][3];

      this.noticias_clarin_2_id = res.data.resultados.clarin[0][2][0];
      this.noticias_clarin_2_titulo = res.data.resultados.clarin[0][2][1];
      this.noticias_clarin_2_foto = res.data.resultados.clarin[0][2][3];

      this.noticias_clarin_3_id = res.data.resultados.clarin[0][3][0];
      this.noticias_clarin_3_titulo = res.data.resultados.clarin[0][3][1];
      this.noticias_clarin_3_foto = res.data.resultados.clarin[0][3][3];

      this.noticias_clarin_4_id = res.data.resultados.clarin[0][4][0];
      this.noticias_clarin_4_titulo = res.data.resultados.clarin[0][4][1];
      this.noticias_clarin_4_foto = res.data.resultados.clarin[0][4][3];

      this.noticias_lanacion_0_id = res.data.resultados.lanacion[0][0][0];
      this.noticias_lanacion_0_titulo = res.data.resultados.lanacion[0][0][1];
      this.noticias_lanacion_0_foto = res.data.resultados.lanacion[0][0][3];

      this.noticias_lanacion_1_id = res.data.resultados.lanacion[0][1][0];
      this.noticias_lanacion_1_titulo = res.data.resultados.lanacion[0][1][1];
      this.noticias_lanacion_1_foto = res.data.resultados.lanacion[0][1][3];

      this.noticias_lanacion_2_id = res.data.resultados.lanacion[0][2][0];
      this.noticias_lanacion_2_titulo = res.data.resultados.lanacion[0][2][1];
      this.noticias_lanacion_2_foto = res.data.resultados.lanacion[0][2][3];

      this.noticias_lanacion_3_id = res.data.resultados.lanacion[0][3][0];
      this.noticias_lanacion_3_titulo = res.data.resultados.lanacion[0][3][1];
      this.noticias_lanacion_3_foto = res.data.resultados.lanacion[0][3][3];

      this.noticias_lanacion_4_id = res.data.resultados.lanacion[0][4][0];
      this.noticias_lanacion_4_titulo = res.data.resultados.lanacion[0][4][1];
      this.noticias_lanacion_4_foto = res.data.resultados.lanacion[0][4][3];

      this.noticias_infobae_0_id = res.data.resultados.infobae[0][0][0];
      this.noticias_infobae_0_titulo = res.data.resultados.infobae[0][0][1];
      this.noticias_infobae_0_foto = res.data.resultados.infobae[0][0][3];

      this.noticias_infobae_1_id = res.data.resultados.infobae[0][1][0];
      this.noticias_infobae_1_titulo = res.data.resultados.infobae[0][1][1];
      this.noticias_infobae_1_foto = res.data.resultados.infobae[0][1][3];

      this.noticias_infobae_2_id = res.data.resultados.infobae[0][2][0];
      this.noticias_infobae_2_titulo = res.data.resultados.infobae[0][2][1];
      this.noticias_infobae_2_foto = res.data.resultados.infobae[0][2][3];

      this.noticias_infobae_3_id = res.data.resultados.infobae[0][3][0];
      this.noticias_infobae_3_titulo = res.data.resultados.infobae[0][3][1];
      this.noticias_infobae_3_foto = res.data.resultados.infobae[0][3][3];

      this.noticias_infobae_4_id = res.data.resultados.infobae[0][4][0];
      this.noticias_infobae_4_titulo = res.data.resultados.infobae[0][4][1];
      this.noticias_infobae_4_foto = res.data.resultados.infobae[0][4][3];

      this.noticias_cronista_0_id = res.data.resultados.cronista[0][0][0];
      this.noticias_cronista_0_titulo = res.data.resultados.cronista[0][0][1];
      this.noticias_cronista_0_foto = res.data.resultados.cronista[0][0][3];

      this.noticias_cronista_1_id = res.data.resultados.cronista[0][1][0];
      this.noticias_cronista_1_titulo = res.data.resultados.cronista[0][1][1];
      this.noticias_cronista_1_foto = res.data.resultados.cronista[0][1][3];

      this.noticias_cronista_2_id = res.data.resultados.cronista[0][2][0];
      this.noticias_cronista_2_titulo = res.data.resultados.cronista[0][2][1];
      this.noticias_cronista_2_foto = res.data.resultados.cronista[0][2][3];

      this.noticias_cronista_3_id = res.data.resultados.cronista[0][3][0];
      this.noticias_cronista_3_titulo = res.data.resultados.cronista[0][3][1];
      this.noticias_cronista_3_foto = res.data.resultados.cronista[0][3][3];

      this.noticias_cronista_4_id = res.data.resultados.cronista[0][4][0];
      this.noticias_cronista_4_titulo = res.data.resultados.cronista[0][4][1];
      this.noticias_cronista_4_foto = res.data.resultados.cronista[0][4][3];

      this.noticias_cronica_0_id = res.data.resultados.cronica[0][0][0];
      this.noticias_cronica_0_titulo = res.data.resultados.cronica[0][0][1];
      this.noticias_cronica_0_foto = res.data.resultados.cronica[0][0][3];

      this.noticias_cronica_1_id = res.data.resultados.cronica[0][1][0];
      this.noticias_cronica_1_titulo = res.data.resultados.cronica[0][1][1];
      this.noticias_cronica_1_foto = res.data.resultados.cronica[0][1][3];

      this.noticias_cronica_2_id = res.data.resultados.cronica[0][2][0];
      this.noticias_cronica_2_titulo = res.data.resultados.cronica[0][2][1];
      this.noticias_cronica_2_foto = res.data.resultados.cronica[0][2][3];

      this.noticias_cronica_3_id = res.data.resultados.cronica[0][3][0];
      this.noticias_cronica_3_titulo = res.data.resultados.cronica[0][3][1];
      this.noticias_cronica_3_foto = res.data.resultados.cronica[0][3][3];

      this.noticias_cronica_4_id = res.data.resultados.cronica[0][4][0];
      this.noticias_cronica_4_titulo = res.data.resultados.cronica[0][4][1];
      this.noticias_cronica_4_foto = res.data.resultados.cronica[0][4][3];

      this.noticias_baenegocios_0_id = res.data.resultados.baenegocios[0][0][0];
      this.noticias_baenegocios_0_titulo =
        res.data.resultados.baenegocios[0][0][1];
      this.noticias_baenegocios_0_foto =
        res.data.resultados.baenegocios[0][0][3];

      this.noticias_baenegocios_1_id = res.data.resultados.baenegocios[0][1][0];
      this.noticias_baenegocios_1_titulo =
        res.data.resultados.baenegocios[0][1][1];
      this.noticias_baenegocios_1_foto =
        res.data.resultados.baenegocios[0][1][3];

      this.noticias_baenegocios_2_id = res.data.resultados.baenegocios[0][2][0];
      this.noticias_baenegocios_2_titulo =
        res.data.resultados.baenegocios[0][2][1];
      this.noticias_baenegocios_2_foto =
        res.data.resultados.baenegocios[0][2][3];

      this.noticias_baenegocios_3_id = res.data.resultados.baenegocios[0][3][0];
      this.noticias_baenegocios_3_titulo =
        res.data.resultados.baenegocios[0][3][1];
      this.noticias_baenegocios_3_foto =
        res.data.resultados.baenegocios[0][3][3];

      this.noticias_baenegocios_4_id = res.data.resultados.baenegocios[0][4][0];
      this.noticias_baenegocios_4_titulo =
        res.data.resultados.baenegocios[0][4][1];
      this.noticias_baenegocios_4_foto =
        res.data.resultados.baenegocios[0][4][3];

      this.noticias_eldestape_0_id = res.data.resultados.eldestape[0][0][0];
      this.noticias_eldestape_0_titulo = res.data.resultados.eldestape[0][0][1];
      this.noticias_eldestape_0_foto = res.data.resultados.eldestape[0][0][3];

      this.noticias_eldestape_1_id = res.data.resultados.eldestape[0][1][0];
      this.noticias_eldestape_1_titulo = res.data.resultados.eldestape[0][1][1];
      this.noticias_eldestape_1_foto = res.data.resultados.eldestape[0][1][3];

      this.noticias_eldestape_2_id = res.data.resultados.eldestape[0][2][0];
      this.noticias_eldestape_2_titulo = res.data.resultados.eldestape[0][2][1];
      this.noticias_eldestape_2_foto = res.data.resultados.eldestape[0][2][3];

      this.noticias_eldestape_3_id = res.data.resultados.eldestape[0][3][0];
      this.noticias_eldestape_3_titulo = res.data.resultados.eldestape[0][3][1];
      this.noticias_eldestape_3_foto = res.data.resultados.eldestape[0][3][3];

      this.noticias_eldestape_4_id = res.data.resultados.eldestape[0][4][0];
      this.noticias_eldestape_4_titulo = res.data.resultados.eldestape[0][4][1];
      this.noticias_eldestape_4_foto = res.data.resultados.eldestape[0][4][3];

      //console.log(this.noticias);
    } catch (e) {
      console.error(e);
    }
  },
};
</script>

<style scoped>
.v-card__title {
  padding: 15px !important;
}
</style>